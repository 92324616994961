<template>
  <div class="summary-template">
    <div class="summary-title">主题</div>
    <div class="summary-subtitle mt-8">{{ detailData.subtitle }}</div>
    <div class="summary-title mt-16">关键词</div>
    <div class="summary-keyword mt-8">
      <div class="summary-keyword-item" v-for="(item, index) in detailData.data" :key="index">
        {{ item }}
      </div>
    </div>
    <div class="summary-title mt-8">概要总结</div>
    <div class="summary-content mt-8" v-html="detailData.summary"></div>
  </div>
</template>

<script>
  const staticData = {
    subtitle: '数字人企业级应用方案',
    data: ['数字人', '数字化大会', '虚拟讲师', '播报讲解'],
    summary:
      '数字人是一种智能生产力工具， 通过数字人技术形成的虚拟人物，可以进行视频播报，交流沟通， 直播互动等等，可以广泛的运用在政府、金融、教育等各种行业和场景。3类典型数字虚拟员工，包括播报式数字员工，如虚拟讲师、新闻播报讲解、短视频内容播报。'
  };
  export default {
    props: {
      menuInfo: {
        required: false
      }
    },
    name: 'component-summary',
    data() {
      return {
        file_default: '//cnstatic01.e.vhall.com/common-static/images/file_default.png'
      };
    },
    computed: {
      detailData() {
        return this.menuInfo.components.data.length > 0 ? this.menuInfo.components : staticData;
      }
    }
  };
</script>

<style lang="less" scoped>
  .summary-template {
    padding: 0 8px 0 12px;
    box-sizing: border-box;
    height: 100%;
    overflow: auto;

    .summary-title {
      width: 100%;
      height: 11px;
      display: flex;
      align-items: center;
      padding-left: 5px;
      font-size: 12px;
      font-weight: 600;
      color: #262626;
      position: relative;

      &::before {
        content: ' ';
        position: absolute;
        left: 0;
        top: 0;
        width: 1.37px;
        height: 11px;
        background: #fb2626;
      }
    }
    .summary-subtitle {
      line-height: 20px;
      text-align: justify;
      font-size: 12px;
      font-weight: 500;
      color: #262626;
    }
    .summary-keyword {
      display: flex;
      flex-wrap: wrap;

      &-item {
        padding: 0 5.5px;
        line-height: 17px;
        text-align: center;
        font-size: 10px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        margin-right: 8px;
        margin-bottom: 8px;
        background: rgba(0, 0, 0, 0.06);
        border-radius: 8px;
      }
    }
    .summary-content {
      line-height: 18px;
      font-size: 12px;
      font-weight: 400;
      color: #262626;
      text-align: justify;
    }

    .mt-8 {
      margin-top: 8px;
    }
    .mt-16 {
      margin-top: 16px;
    }
  }
</style>
